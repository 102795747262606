import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie'

export const Logout = () => {

    const logout = () => {

    }

    return (
        <div onClick={logout}>Logout</div>
    )
}

export const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
    const [userInfo, setUserInfo] = useState();
  
    useEffect(() => {
        if (cookies.jwt) {
            console.log(cookies.jwt)
            // setUserInfo(JSON.parse(cookies.jwt || ''))
        }
    },[setUserInfo, cookies.jwt])

    if(userInfo) {
        return <Logout />
    }
    return (
        <div>
        <a href="https://login.legendsofarnadia.com/login?client_id=58lufls2dg0l5mngchk5c86gm8&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fapi.legendsofarnadia.com%2Flogin">Login</a>
        </div>
    );
}