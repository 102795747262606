import logo from './dragon.svg';
import './App.css';
import { Login } from './components/login'

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Legends of Arnadia</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <Login />
      </header>
    </div>
  );
}

export default App;
